import gql from 'graphql-tag';

export const Q_USER = gql`
  query {
    me {
      id
      data {
        firstName
        lastName
        name
        email
        enabled
        favorites {
          id
          label
          isPublic
          businesses
          businessData {
            id
            data {
              name
              headerImage
              slug
            }
          }
        }
        adminRole
      }
      business {
        id
        data {
          isValidated
          registerStatus
          award2025SephoraTypeformId
          award2025TypeformId
          award2025VsTypeformId
          name
          slug
        }
      }
      corporation {
        isOwner
        corporation {
          data {
            isValidated
            name
            ownerId
          }
        }
      }
    }
  }
`;

export const Q_USER_PUBLIC_BOARDS = gql`
  query userPublicBoards($userId: String!) {
    userPublicBoards(userId: $userId) {
      id
      label
      isPublic
      businesses
      businessData {
        id
        data {
          name
          headerImage
          slug
        }
      }
    }
  }
`;

export const M_SIGNUP = gql`
  mutation signUp(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
  ) {
    signUp(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        password: $password
      }
    )
  }
`;

export const M_LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      token
    }
  }
`;

export const M_CONFIRM_EMAIL = gql`
  mutation confirmEmail($code: String!) {
    confirmEmail(input: { code: $code })
  }
`;

export const M_REQUEST_EMAIL_CONFIRMATION = gql`
  mutation requestEmailConfirmation($email: String!) {
    requestEmailConfirmation(input: { email: $email })
  }
`;

export const M_EDIT_USER = gql`
  mutation editUser($id: String!, $firstName: String!, $lastName: String!) {
    editUser(input: { id: $id, firstName: $firstName, lastName: $lastName })
  }
`;

export const M_CHANGE_PASSWORD = gql`
  mutation changePassword($id: String!, $password: String!) {
    changePassword(input: { id: $id, password: $password })
  }
`;

export const M_REQUEST_RESET_PASSWORD = gql`
  mutation requestResetPassword($email: String!) {
    requestResetPassword(input: { email: $email })
  }
`;

export const M_FINALIZE_RESET_PASSWORD = gql`
  mutation finalizeResetPassword($password: String!, $code: String!) {
    finalizeResetPassword(input: { password: $password, code: $code })
  }
`;

export const M_ADD_FAVORITE_BOARD = gql`
  mutation createBoard($userId: String!, $label: String!, $isPublic: Boolean!) {
    createBoard(
      input: { userId: $userId, label: $label, isPublic: $isPublic }
    ) {
      id
      data {
        favorites {
          id
        }
      }
    }
  }
`;

export const M_EDIT_FAVORITE_BOARD = gql`
  mutation editBoard(
    $userId: String!
    $boardId: String!
    $label: String!
    $isPublic: Boolean!
  ) {
    editBoard(
      input: {
        userId: $userId
        boardId: $boardId
        label: $label
        isPublic: $isPublic
      }
    ) {
      id
      data {
        favorites {
          id
        }
      }
    }
  }
`;

export const M_DELETE_FAVORITE_BOARD = gql`
  mutation deleteBoard($userId: String!, $boardId: String!) {
    deleteBoard(input: { userId: $userId, boardId: $boardId }) {
      id
      data {
        favorites {
          id
        }
      }
    }
  }
`;

export const M_ADD_FAVORITE_BUSINESS = gql`
  mutation addToBoard(
    $userId: String!
    $boardId: String!
    $businessId: String!
  ) {
    addToBoard(
      input: { userId: $userId, boardId: $boardId, businessId: $businessId }
    ) {
      id
      data {
        favorites {
          id
        }
      }
    }
  }
`;

export const M_DELETE_FAVORITE_BUSINESS = gql`
  mutation deleteFromBoard(
    $userId: String!
    $boardId: String!
    $businessId: String!
  ) {
    deleteFromBoard(
      input: { userId: $userId, boardId: $boardId, businessId: $businessId }
    ) {
      id
      data {
        favorites {
          id
        }
      }
    }
  }
`;
