import React, { ReactNode, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import NextNprogress from 'nextjs-progressbar';
import { useLazyQuery } from '@apollo/react-hooks';
import { Q_BUSINESS_ABOUT } from '../../graphql/business';
import { AwardModalTypes, ModalTypes } from '../../context/modalContext';
import { Colors, Routes } from '../../helpers/constants';
import { useAuth, useModal, usePreview } from '../../hooks';
import useScrollPosition from '../../helpers/useScrollPosition';
import FavoriteModal from '../favorites/FavoriteModal';
import RegisterBusinessForAwardModal from '../registration/RegisterBusinessForAwardModal';
import RegisterBusinessForGenericAwardModal from '../registration/RegisterBusinessForGenericAwardModal';
import RegisterBusinessForAwardSephoraModal from '../registration/RegisterBusinessForAwardSephoraModal';
import RegisterModal from '../registration/RegisterModal';
import LoginModal from '../profile/LoginModal';
import EditBoardModal from '../favorites/EditBoardModal';
import { Footer, Header } from './';
import PageWrapper from './PageWrapper';
import { BusinessHeader } from '../business';
import PopUpModal from '../home/PopUpModal';

type Props = {
  children?: ReactNode;
};

let darkSections = null;

const Layout = ({ children }: Props) => {
  const { user } = useAuth();
  const {
    modalType,
    setModalType,
    awardModalType,
    setAwardModalType,
  } = useModal();
  const { previewMode } = usePreview();
  const scrollPosition = useScrollPosition();
  const [showModal, setShowModal] = useState(ModalTypes.NONE);
  const [darkMode, setDarkMode] = useState(false);
  const [request, setRequest] = useState(null);
  const [showFooter, setShowFooter] = useState(false);
  const { query } = useRouter();
  const { pathname } = useRouter();

  useEffect(() => {
    setDarkMode(false);
    darkSections = document.querySelectorAll(
      'main.bg-rust, section.bg-rust, section.bg-nutmeg, section.bg-nutmeg-30, section.bg-pink-tan, section.bg-salmon'
    );
    updateDarkMode();
  }, [pathname]);

  useEffect(() => {
    if (!darkSections) return;
    const mainContainer = document.querySelector('main');
    if (!mainContainer) return;
    const mainBottom =
      mainContainer.getBoundingClientRect().bottom - window.innerHeight;
    updateDarkMode();
    setShowFooter(mainBottom <= 0);
  }, [scrollPosition]);

  const updateDarkMode = () => {
    let section = null;
    for (let i = 0; i < darkSections.length; i += 1) {
      section = darkSections[i];
      const { y, height } = section.getBoundingClientRect();
      if (y < 40 && y + height > 40) {
        setDarkMode(true);
        break;
      } else {
        setDarkMode(false);
      }
    }
  };

  useEffect(() => {
    if (modalType !== ModalTypes.NONE && showModal !== ModalTypes.NONE) {
      if (request) {
        setShowModal(modalType);
        setRequest(null);
      } else {
        setRequest(modalType);
      }
    } else {
      if (modalType === ModalTypes.NONE && request) {
        setModalType(request);
        return;
      }
      // if modal is closed, clean up our award modal type state
      if (
        modalType === ModalTypes.NONE &&
        awardModalType !== AwardModalTypes.NONE
      ) {
        setAwardModalType(AwardModalTypes.NONE);
      }
      setShowModal(modalType);
    }
  }, [modalType]);

  const [getBusinessAbout, businessData] = useLazyQuery(Q_BUSINESS_ABOUT, {
    variables: {
      slug: query.slug,
    },
  });

  const handleBusinessUpdate = () => {
    businessData.refetch();
  };

  const isBusinessPage = pathname === Routes.BUSINESS;
  const isHomepage = pathname === Routes.HOME;

  useEffect(() => {
    if (isBusinessPage && !businessData.data) {
      getBusinessAbout();
    }
  }, [query.slug]);

  let isPublishable = false;
  if (
    isBusinessPage &&
    user &&
    businessData.data &&
    businessData.data.business
  ) {
    const { business } = businessData.data;
    isPublishable =
      user.id === business.data.ownerId ||
      (user.data.adminRole && user.data.adminRole.toUpperCase() === 'ADMIN');
  }

  const handleModalClose = () => {
    setModalType(ModalTypes.NONE);
  };

  return (
    <>
      <Header
        darkMode={darkMode}
        isPublishable={isPublishable}
        business={
          businessData && businessData.data ? businessData.data.business : null
        }
        onPublishChanged={businessData.refetch}
      />
      {isBusinessPage ? (
        <PageWrapper
          title={
            businessData.data && businessData.data.business
              ? businessData.data.business.data.name
              : !businessData.loading
              ? 'Not Found'
              : ''
          }
        >
          {businessData.data && businessData.data.business && (
            <BusinessHeader
              previewMode={previewMode}
              data={businessData.data}
              onUpdate={handleBusinessUpdate}
            />
          )}
          {children}
        </PageWrapper>
      ) : (
        <>{children}</>
      )}
      {isHomepage && <PopUpModal />}
      {(showModal === ModalTypes.REGISTER ||
        showModal === ModalTypes.REGISTER_BUSINESS ||
        showModal === ModalTypes.REGISTER_CORPORATION) && (
        <RegisterModal modalType={showModal} onClose={handleModalClose} />
      )}
      {showModal === ModalTypes.REGISTER_BUSINESS_FOR_AWARD && (
        <RegisterBusinessForAwardModal onClose={handleModalClose} />
      )}
      {showModal === ModalTypes.REGISTER_BUSINESS_FOR_GENERIC_AWARD && (
        <RegisterBusinessForGenericAwardModal
          awardModalType={awardModalType}
          onClose={handleModalClose}
        />
      )}
      {showModal === ModalTypes.REGISTER_BUSINESS_FOR_SEPHORA_AWARD && (
        <RegisterBusinessForAwardSephoraModal onClose={handleModalClose} />
      )}
      {showModal === ModalTypes.LOGIN && (
        <LoginModal
          shouldClose={request && request !== ModalTypes.LOGIN}
          onClose={handleModalClose}
        />
      )}
      {showModal === ModalTypes.FAVORITE && (
        <FavoriteModal onClose={handleModalClose} />
      )}
      {(showModal === ModalTypes.FAVORITE_EDIT ||
        showModal === ModalTypes.FAVORITE_DELETE ||
        showModal === ModalTypes.FAVORITE_SHARE) && (
        <EditBoardModal onClose={handleModalClose} editType={modalType} />
      )}
      <Footer show={showFooter} />
      <NextNprogress
        color={Colors.BROWN_RUST}
        startPosition={0.3}
        stopDelayMs={200}
        height={4}
        options={{ easing: 'ease', speed: 500 }}
      />
    </>
  );
};

export default Layout;
