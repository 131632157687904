import React, { Dispatch, useState } from 'react';
import { BusinessRecord } from '../types/business';
import { FavoriteBoards } from '../types/user';

export enum ModalTypes {
  NONE = 'NONE',
  ACCESSIBILITY = 'ACCESSIBILITY', // triggers Accessibee modal, not ours
  REGISTER = 'REGISTER',
  REGISTER_BUSINESS = 'REGISTER_BUSINESS',
  REGISTER_BUSINESS_FOR_AWARD = 'REGISTER_BUSINESS_FOR_AWARD',
  REGISTER_BUSINESS_FOR_GENERIC_AWARD = 'REGISTER_BUSINESS_FOR_GENERIC_AWARD',
  REGISTER_BUSINESS_FOR_SEPHORA_AWARD = 'REGISTER_BUSINESS_FOR_SEPHORA_AWARD',
  REGISTER_CORPORATION = 'REGISTER_CORPORATION',
  LOGIN = 'LOGIN',
  FAVORITE = 'FAVORITE',
  FAVORITE_EDIT = 'FAVORITE_EDIT',
  FAVORITE_DELETE = 'FAVORITE_DELETE',
  FAVORITE_SHARE = 'FAVORITE_SHARE',
  SHOP_SITE = 'SHOP_SITE',
}

export enum AwardModalTypes {
  NONE = 'NONE',
  ACHIEVEMENT_AWARD = 'ACHIEVEMENT_AWARD',
  SEPHORA_AWARD = 'SEPHORA_AWARD',
  VICTORIAS_SECRET_PROCESS = 'VICTORIAS_SECRET_PROCESS',
}

export const ModalContext = React.createContext<{
  awardModalType: AwardModalTypes;
  setAwardModalType: Dispatch<any>;
  modalType: ModalTypes;
  setModalType: Dispatch<any>;
  favoriteBoard: FavoriteBoards | null;
  setFavoriteBoard: Dispatch<FavoriteBoards>;
  favoriteBusiness: BusinessRecord | null;
  setFavoriteBusiness: Dispatch<BusinessRecord>;
}>({
  awardModalType: AwardModalTypes.NONE,
  setAwardModalType: () => null,
  modalType: ModalTypes.NONE,
  setModalType: () => null,
  favoriteBoard: null,
  setFavoriteBoard: () => null,
  favoriteBusiness: null,
  setFavoriteBusiness: () => null,
});

export function ModalProvider({ children }: { children: React.ReactNode }) {
  const [modalType, setModalType] = useState(ModalTypes.NONE);
  const [awardModalType, setAwardModalType] = useState(AwardModalTypes.NONE);
  const [favoriteBusiness, setFavoriteBusiness] = useState(null);
  const [favoriteBoard, setFavoriteBoard] = useState(null);

  return (
    <ModalContext.Provider
      value={{
        awardModalType,
        setAwardModalType,
        modalType,
        setModalType,
        favoriteBoard,
        setFavoriteBoard,
        favoriteBusiness,
        setFavoriteBusiness,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}
